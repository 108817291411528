import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import moment from "moment-timezone";

const timezones = [
  "America/Los_Angeles",
  "America/New_York",
  "Europe/London",
  ["America/Chicago", "America/Houston"]
];

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const TimesS = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  background-color: #000;
`;

const TimeS = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
`;

const TimeDisplayS = styled.div`
  font-size: 80px;
  @media (min-width: 1500px) {
    font-size: 150px;
  }
`;

const TimeLocationS = styled.div`
  font-size: 24px;
  @media (min-width: 1500px) {
    font-size: 36px;
  }
`;

function App() {
  const [currentTime, setCurrentTime] = useState(moment());

  useInterval(() => {
    setCurrentTime(moment());
  }, 1000);

  return (
    <>
      <TimesS>
        {timezones.map(tz => {
          const zone = Array.isArray(tz) ? tz[0] : tz;
          const label = Array.isArray(tz) ? tz[1] : tz;
          return (
            <TimeS>
              <TimeDisplayS>
                {currentTime.tz(zone).format("HH:mm")}
              </TimeDisplayS>
              <TimeLocationS>{label}</TimeLocationS>
            </TimeS>
          );
        })}
      </TimesS>
    </>
  );
}

export default App;
